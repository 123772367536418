<template>
    <mercur-card class="fill full-height-layout">
        <grid-header :quickSearch.sync="filters.search">
            Extra days configurations
            <template slot="actions">
                <mercur-button
                    @click="openExtraDaysConfiguration(null)"
                    class="btn btn-raised btn-yellow text-uppercase"
                    v-if="isAllowedTo('addExtraDaysConfiguration')">
                    <i class="fas fa-plus"></i>
                    Add extra days configuration
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            :quickSearch="filters.search"
            v-if="isAllowedTo('getAllExtraDaysConfiguration')"
        ></data-table>
        <extra-days-configuration-popup
            :dialog-active.sync="extraDaysConfigurationPopupActive"
            :extra-days-configuration-details="extraDaysConfigurationDetails"
            @extra-days-configuration-added="$refs.grid.refreshGrid()"
            :available-service-levels="availableServiceLevels"
        ></extra-days-configuration-popup>

        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive" width="600px">
            <div slot="header"><h3>Are you sure you want to remove extra days configuration?</h3></div>
            <div slot="default">It is not possible to recover the extra days configuration after deleting.</div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-raised text-uppercase" @click="deleteExtraDays(extraDaysConfigurationDetails)">Delete</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import ExtraDaysConfigurationPopup from '../../components/extra_days/ExtraDaysConfigurationPopup'

export default {
    name: 'ExtraDaysOverview',
    components: { ExtraDaysConfigurationPopup, DataTable, GridHeader },
    data () {
        return {
            extraDaysConfigurationPopupActive: false,
            extraDaysConfigurationDetails: {},
            deleteConfirmationDialogActive: false,
            availableServiceLevels: null,
            options: {
                columns: {
                    'Supplier': {
                        field: 'supplierName',
                        sortable: true,
                    },
                    'ProductHash': {
                        field: 'productHash',
                    },
                    'Service Level': {
                        field: 'serviceLevelId',
                    },
                    'Country': {
                        field: 'countryCode',
                    },
                    'Extra days': {
                        field: 'days',
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-trash',
                        text: 'Delete',
                        onClick: ({ data }) => this.openDeleteConfirmDialog(data),
                    },
                ],

                quickSearchColumns: ['agreementName'],
            },
            url: CONFIG.API.ROUTES.EXTRA_DAYS.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },
    methods: {
        getSupplierIntegrationDetailRoute (params) {
            return {
                name: 'SupplierIntegrationDetail',
                params: {
                    supplierIntegrationConfigId: params.supplierIntegrationConfigId,
                },
            }
        },
        openExtraDaysConfiguration (params = null) {
            this.extraDaysConfigurationDetails = params || {
                supplierId: null,
                serviceLevelId: null,
                countryCode: null,
                days: 1,
            }
            this.extraDaysConfigurationPopupActive = true
        },
        openDeleteConfirmDialog (data) {
            this.extraDaysConfigurationDetails = data
            this.deleteConfirmationDialogActive = true
        },
        deleteExtraDays () {
            if (!this.isAllowedTo('removeExtraDaysConfiguration')) {
                return
            }

            this.extraDaysConfigurationPopupActive = false
            this.loading = true
            const jobName = JSON.stringify(this.localConfiguration)

            this.addJob(jobName)
            const url = CONFIG.API.ROUTES.EXTRA_DAYS.REMOVE
                .replace('{extraDaysConfigurationId}', this.extraDaysConfigurationDetails.extraDaysConfigurationId)

            this.$api.post(url, this.extraDaysConfigurationDetails).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Extra days configuration deleted.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Deleting extra days configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
    },
    created () {
        if (!this.isAllowedTo('getAllServiceLevels')) {
            return
        }
        this.$store.dispatch('carriers/fetchAllServiceLevels').then(data => {
            this.availableServiceLevels = data.data.items
        })
    },
}
</script>
