<template>
    <mercur-dialog :is-open.sync="localDialogActive" width="700px">
        <div slot="header"><h3>Extra days</h3></div>
        <div slot="default">
            <div>
                <mercur-select data-test="extraDaysPopUpSupplierId" v-model="$v.details.supplierId.$model">
                    <template slot="label">Supplier</template>
                    <option
                        v-for="supplier in allSuppliers"
                        :key="supplier.supplierId"
                        :value="supplier.supplierId">
                        {{supplier.supplierName}}
                    </option>
                    <template slot="note">
                        <span class="form-error" v-if="$v.details.supplierId.$error">Supplier is required</span>
                    </template>
                </mercur-select>

                <mercur-select data-test="extraDaysPopUpServiceLevelId" v-if="availableServiceLevels" v-model="$v.details.serviceLevelId.$model">
                    <template slot="label">Service level</template>
                    <option
                        v-for="serviceLevel in availableServiceLevels"
                        :key="serviceLevel.serviceLevelId"
                        :value="serviceLevel.serviceLevelId">
                        {{serviceLevel.serviceLevelName}}
                    </option>
                    <template slot="note">
                        <span class="form-error" v-if="$v.details.serviceLevelId.$error">
                            One of the fields supplier, service level, product hash or country needs to be filled
                        </span>
                    </template>
                </mercur-select>

                <div class="row">
                    <div class="full-height-layout col-6">
                        <mercur-select data-test="extraDaysPopUpProductName" v-if="supplierProducts" v-model="$v.details.productHash.$model">
                            <template slot="label">Product name</template>
                            <option
                                v-for="product in supplierProducts"
                                :key="product.productHash"
                                :value="product.productHash">
                                {{product.productConfigurationName}}
                            </option>
                        </mercur-select>
                    </div>
                    <div class="full-height-layout col-6">
                        <mercur-input data-test="extraDaysPopUpProductHash"
                            v-model="$v.details.productHash.$model"
                            :class="{'form-invalid': $v.details.productHash.$error}">
                            Product hash
                            <template slot="note">
                                <span class="form-error" v-if="!$v.details.productHash.oneOfRequired">
                                    One of the fields supplier, service level, product hash or country needs to be filled
                                </span>
                            </template>
                        </mercur-input>
                    </div>
                </div>

                <mercur-select data-test="extraDaysPopUpCountry" v-model="$v.details.countryCode.$model">
                    <template slot="label">Country</template>
                    <option
                        v-for="country in availableCountries"
                        :key="country.countryShortCode"
                        :value="country.countryShortCode">
                        {{country.countryName}}
                    </option>
                    <template slot="note">
                        <span class="form-error" v-if="$v.details.countryCode.$error">Country is required</span>
                    </template>
                </mercur-select>

                <mercur-input data-test="extraDaysPopUpDays"
                    v-model.number="$v.details.days.$model"
                    :class="{'form-invalid': $v.details.days.$error}"
                    type="number">
                    Extra days
                    <template slot="note">
                        <span class="form-error" v-if="!$v.details.days.required">>Required</span>
                    </template>
                </mercur-input>
            </div>

            <div class="row justify-content-between px-3 mt-4">
                <div class="text-left">
                    <mercur-button data-test="extraDaysPopUpClose" class="btn btn-raised text-uppercase" @click="localDialogActive = false">Close</mercur-button>
                </div>
                <div class="text-right">
                    <mercur-button data-test="extraDaysPopUpSave" class="btn btn-yellow text-uppercase" @click="saveExtraDayConfiguration()" :disabled="$v.$invalid || loading">Save</mercur-button>
                </div>
            </div>
        </div>
        <div slot="footer">
        </div>
        <mercur-progress-bar indeterminate v-if="loading || productsLoading" />
    </mercur-dialog>
</template>

<script>
import regions from 'country-region-data'
import { validationMixin } from 'vuelidate'
import {
    required,
    minValue,
    and,
    requiredUnless,
} from 'vuelidate/lib/validators'
import CONFIG from '@root/config'

const oneOfTheValues = values => {
    return values.countryCode || values.supplierId || values.serviceLevelId || values.productHash
}

export default {
    name: 'ExtraDaysConfigurationPopup',
    props: ['dialogActive', 'extraDaysConfigurationDetails', 'availableServiceLevels'],
    mixins: [validationMixin],
    data () {
        return {
            availableCountries: regions,
            loading: false,
            productsLoading: false,
            details: null,
            allSuppliers: [],
            supplierProducts: [],
        }
    },
    computed: {
        localDialogActive: {
            get () {
                return this.dialogActive
            },
            set (value) {
                this.$emit('update:dialogActive', value)
            },
        },
    },
    validations: {
        details: {
            supplierId: {
                required,
            },
            serviceLevelId: {
                oneOfRequired: requiredUnless(oneOfTheValues),
            },
            countryCode: {
                required,
            },
            productHash: {
                oneOfRequired: requiredUnless(oneOfTheValues),
            },
            days: {
                required: and(required, minValue(1)),
            },
        },
    },
    methods: {
        saveExtraDayConfiguration () {
            if (!this.isAllowedTo('addExtraDaysConfiguration')) {
                return
            }

            ['supplierId', 'serviceLevelId', 'countryCode', 'productHash'].forEach(key => {
                if (this.details[key] === '') {
                    this.details[key] = null
                }
            })

            const url = CONFIG.API.ROUTES.EXTRA_DAYS.ADD
            this.addJob(url)
            this.loading = true

            this.$api.post(url, this.details).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Extra days configuration successfully added.`,
                })
                this.$emit('extra-days-configuration-added', this.details)
                this.localDialogActive = false
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving extra days configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },

        handleDialogOpened () {
            this.$set(this, 'details', {
                supplierId: null,
                serviceLevelId: null,
                productHash: null,
                countryCode: null,
                ...this.extraDaysConfigurationDetails,
            })
            this.$v.$reset()
        },
    },
    watch: {
        extraDaysConfigurationDetails: {
            handler () {
                this.handleDialogOpened()
            },
            deep: true,
            immediate: true,
        },
        'details.supplierId' (supplierId) {
            if (!supplierId) {
                return
            }
            this.$set(this, 'supplierProducts', [])
            const productsParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            productsParams.request.sortModel.push({
                colId: 'productConfigurationName',
                sort: 'asc',
            })
            const productsUrl = CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', supplierId)
            this.addJob(productsUrl)
            this.productsLoading = true
            this.$api.post(productsUrl, productsParams).then(({ data }) => {
                this.$set(this, 'supplierProducts', data.data.items)
            }).finally(() => {
                this.finishJob(productsUrl)
                this.productsLoading = false
            })
        },
    },
    created () {
        this.handleDialogOpened()
        const suppliersParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        suppliersParams.request.sortModel.push({
            colId: 'supplierName',
            sort: 'asc',
        })
        if (!this.isAllowedTo('countOrdersBySupplierAndStatus') || !this.isAllowedTo('getSuppliersByParentId')) {
            return
        }
        const suppliersUrl = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(suppliersUrl)
        this.$api.post(suppliersUrl, suppliersParams).then(({ data }) => {
            this.$set(this, 'allSuppliers', data.data.items)
        }).finally(() => {
            this.finishJob(suppliersUrl)
        })
    },
}
</script>
